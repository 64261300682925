const images = {
  'enter-info': require('./salesforce-instructional-images/enter-info.gif'),
  'enable-auth-paste-callback': require('./salesforce-instructional-images/enable-oauth.png'),
  'add-scopes': require('./salesforce-instructional-images/add-scopes.gif'),
  'click-save': require('./salesforce-instructional-images/click-save.png'),
  'copy-consumer-key': require('./salesforce-instructional-images/copy-consumer-key.gif'),
  'copy-consumer-secret': require('./salesforce-instructional-images/copy-consumer-secret.gif'),
  'click-setup': require('./salesforce-instructional-images/click-setup.gif'),
  'click-app-manager': require('./salesforce-instructional-images/click-app-manager.gif'),
  'click-new-connected-app': require('./salesforce-instructional-images/click-new-connected-app.gif'),
  'classic-click-setup': require('./salesforce-instructional-images/classic-click-setup.gif'),
  'classic-new-connected-app': require('./salesforce-instructional-images/classic-new-connected-app.gif'),
  'sample-email': require('./salesforce-instructional-images/sample-email.gif'),
  'classic-find-cors': require('./salesforce-instructional-images/classic-find-cors.gif'),
  'lightning-cors': require('./salesforce-instructional-images/lightning-cors.gif'),
  'add-cors-record': require('./salesforce-instructional-images/add-record-to-cors.gif')
}

export default images
