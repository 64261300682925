import React, { Component, ReactElement } from 'react'
import ITab from '../ITab'
import './style.css'

interface Props {
  tabs: ITab[]
}

interface State {
  activeTabIndex: number
  overlayClass: string
}

class TabbedViewContainer extends Component<Props, State> {
  state = {
    activeTabIndex: 0,
    overlayClass: 'tab-overlay'
  }

  // @ts-ignore
  updateActiveTab = (index): void => {
    const { activeTabIndex } = this.state

    if (index !== activeTabIndex) {
      this.setState({
        activeTabIndex: index,
        overlayClass: 'tab-overlay fade'
      })
    }
  }

  fadeInContent = () => {
    this.setState({ overlayClass: 'tab-overlay' })
  }

  render (): ReactElement {
    const { activeTabIndex, overlayClass } = this.state

    return (
      <div className='tabbed-view-container'>
        <div className='tab-headers-container'>
        {
          this.props.tabs.map((tab, index) => {
            return (
              <div
                className={`tab ${index === activeTabIndex ? 'active' : ''}`}
                key={tab.title}
                onClick={() => this.updateActiveTab(index)}
              >
                {tab.title}
              </div>
            )
          })
        }
        </div>
        <div
          className={overlayClass}
          onAnimationEnd={() => this.fadeInContent()}
        >
        {
          this.props.tabs.map((tab, index) => {
            return (
              <div
                className={`tab-panel ${index === activeTabIndex ? 'active' : ''}`}
                key={tab.title}
              >
                {tab.content}
              </div>
            )
          })
        }
        </div>
      </div>
    )
  }
}

export default TabbedViewContainer
