import React, { Component, ReactElement } from 'react'
import './style.css'

interface IProps {
  bottom?: boolean
  compact?: boolean
  text: string
}

class Tooltip extends Component<IProps> {
  static defaultProps = {
    bottom: false,
    compact: false
  }

  render (): ReactElement {
    const { bottom, compact } = this.props

    return (
      <div className='tooltip-wrapper'>
        <div className={`tooltip ${bottom ? 'bottom' : ''} ${compact ? 'compact' : ''}`}>
          {this.props.text}
        </div>
        {this.props.children}
      </div>
    )
  }
}

export default Tooltip
