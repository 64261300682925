import React, { Component, ReactElement } from 'react'
import Copy from '../../Copy'
import ShowMe from '../ShowMe'
import './style.css'

class SFConfigureNewConnectedApp extends Component {
  render (): ReactElement {
    return (
      <div className='sf-configure-new-connected-app'>
        <h2>Configure the New Connected App</h2>
        <ol>
          <li>
            Copy these values and paste them into their fields:
            <br />
            <ul>
              <li>Connected App Name: <Copy>Databased Software</Copy></li>
              <li>API Name: <Copy>Databased_Software</Copy></li>
              <li>Contact Email: <Copy>softwareadmin@databased.com</Copy></li>
            </ul>
            <ShowMe image='enter-info' />
          </li>
          <li>
            Check the box 'Enable OAuth Settings' and paste
            '<Copy>https://login.salesforce.com/services/oauth2/success</Copy>' into the 'Callback URL' box
            <ShowMe image='enable-auth-paste-callback' />
          </li>
          <li>
            Under 'Selected OAuth Scopes' add the following scopes:
            <ul>
              <li>Manage user data via APIs (api)</li>
              <li>Manage user data via Web browsers (web)</li>
              <li>Perform requests at any time (refresh_token, offline_access)</li>
            </ul>
            <ShowMe image='add-scopes' />
          </li>
          <li>
            Click the 'Save' button
          </li>
        </ol>
      </div>
    )
  }
}

export default SFConfigureNewConnectedApp
