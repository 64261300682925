import React, { Component, ReactElement } from 'react'

interface Props {
  accept?: string,
  autocomplete?: boolean,
  autoFocus?: boolean,
  alt?: string,
  checked?: boolean,
  class?: string,
  dirname?: string,
  disabled?: boolean,
  defaultValue?: string,
  form?: string,
  formaction?: string,
  formenctype?: string,
  formmethod?: string,
  formnovalidate?: string,
  formtarget?: string,
  height?: string,
  id?: string,
  list?: string,
  max?: string,
  maxlength?: string,
  min?: string,
  multiple?: boolean
  name: string,
  onChange?: (event: React.ChangeEvent<HTMLInputElement>, component: Input) => void,
  pattern?: string,
  placeholder?: string,
  readonly?: string,
  required?: boolean,
  size?: number,
  src?: string,
  step?: string,
  type: string,
  value?: string,
  width?: string
}

class Input extends Component<Props> {
  static defaultProps = {
    pattern: '.*',
    required: false
  }

  pristine = true
  error = ''

  refresh = () => {
    this.setState({})
  }

  onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    this.pristine = false
    if (this.props.onChange != null) {
      this.props.onChange(event, this)
    }
  }

  onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.props.onChange != null) {
      this.props.onChange(event, this)
    }
  }

  hasError = () => {
    return this.error !== ''
  }

  render (): ReactElement {
    return (
      <div>
        <input
          {...this.props}
          onChange={this.onChange}
          onBlur={this.onBlur}
          style={ (this.hasError() ? { marginBottom: 'var(--spacing-size-a)' } : {}) }
        />
        { this.hasError() && <p className='error'>{this.error}</p> }
      </div>
    )
  }
}

export default Input