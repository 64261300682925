import React, { Component, ReactElement } from 'react'
import images from '../../images'
import './style.css'

interface IProps {
  altText?: string
  image: string
}

class ShowMe extends Component<IProps> {
  static defaultProps = {
    altText: ''
  }

  render (): ReactElement {
    const { altText, image } = this.props
    // @ts-ignore
    const img: string = images[image]

    return (
      <details className='showMe'>
        <summary>Show me how and where to go to complete this step</summary>
        <img src={img} alt={altText} />
      </details>
    )
  }
}

export default ShowMe
