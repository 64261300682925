import React, { Component, ReactElement } from 'react'
import TabbedViewContainer from '../../TabbedViewContainer'
import ShowMe from '../ShowMe'
import './style.css'
import Copy from '../../Copy'

class SFAddNewConnectedAppInstructions extends Component {
  renderLightningInstructions = (): ReactElement => {
    return (
      <div>
        <h2>Add DataBased URL to CORS policy</h2>
        <ol>
          <li>
            Go to 'Setup' on your Salesforce Org
            <ShowMe image='click-setup' />
          </li>
          <li>
            Use the Quick Find box to search for and open 'CORS'
            <ShowMe image='lightning-cors' />
          </li>
          <li>
            Click new, then in, <var>Origin URL Pattern</var> paste <Copy>https://*.databased.com</Copy>
            <ShowMe image='add-cors-record' />
          </li>
        </ol>
          <h2>Create a New Connected App</h2>
        <ol>
          <li>
            Go back to 'Setup' on your Salesforce Org
            <ShowMe image='click-setup' />
          </li>
          <li>
            Use the Quick Find box to search for and open 'App Manager'
            <ShowMe image='click-app-manager' />
          </li>
          <li>
            In the top right of the window click the button labeled 'New Connected App'
            <ShowMe image='click-new-connected-app' />
          </li>
        </ol>
      </div>
    )
  }

  renderClassicInstructions = (): ReactElement => {
    return (
      <div>
        <h2>Add DataBased URL to CORS policy</h2>
        <ol>
          <li>
            Go to 'Setup' on your Salesforce Org
            <ShowMe image='classic-click-setup' />
          </li>
          <li>
            Use the Quick Find box to search for and open 'CORS'
            <ShowMe image='classic-find-cors' />
          </li>
          <li>
            Click new, then in, <var>Origin URL Pattern</var> paste <Copy>https://*.databased.com</Copy>
            <ShowMe image='add-cors-record' />
          </li>
          </ol>
            <h2>Create a New Connected App</h2>
          <ol>
          <li>
            Go back to 'Setup' on your Salesforce Org
            <ShowMe image='classic-click-setup' />
          </li>
          <li>
            On the side menu, scroll down to 'Build' {'>'} 'Create' {'>'} 'Apps'.
            Then scroll in the new window down to 'Connected Apps' and click 'New'
            <ShowMe image='classic-new-connected-app' />
          </li>
        </ol>
      </div>
    )
  }

  render (): ReactElement {
    return (
      <div className='sf-add-new-connected-app-instructions'>
        <h1>Connect Salesforce</h1>
        <TabbedViewContainer
          tabs={[
            {
              content: this.renderLightningInstructions(),
              title: 'Lightning Edition'
            },
            {
              content: this.renderClassicInstructions(),
              title: 'Classic Edition'
            }
          ]}
        />
      </div>
    )
  }
}

export default SFAddNewConnectedAppInstructions
