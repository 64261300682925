import React, { Component, ReactElement } from 'react'
import { copyToClipboard } from '../functions'
import Tooltip from '../Tooltip'
import './style.css'

interface IProps {
  text?: string
}

const defaultMessage = 'Copy to clipboard'
class Copy extends Component<IProps> {
  static defaultProps = {
    text: ''
  }

  state = {
    message: defaultMessage
  }

  copy = (): void => {
    let { text } = this.props

    if (text === '') {
      //@ts-ignore
      text = this.props.children.toString()
    }

    copyToClipboard(text)
    this.setState({
      message: `Copied: ${text}`
    })
  }

  resetMessage = (): void => {
    this.setState({
      message: defaultMessage
    })
  }

  render (): ReactElement {
    const { message } = this.state

    return (
      <div className='copy-wrapper' onMouseOut={this.resetMessage} onClick={this.copy}>
        <Tooltip text={message}>
          {this.props.children}
        </Tooltip>
      </div>
    )
  }
}

export default Copy
