/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, ReactElement } from 'react'
import Input from '../../Input'
import ShowMe from '../ShowMe'
import './style.css'

class SFGiveAuthorization extends Component {
  render (): ReactElement {
    return (
      <div className='sf-give-authorization'>
      <form action="https://sync.databased.com/salesforce/connect/info" method="POST">
      <h2>Send Connection Information to DataBased</h2>
      <ol>
          <li>
            <label htmlFor='company-name'>Company Name</label>
            <Input
              id='company-name'
              name='companyName'
              required
              type='text'
              placeholder="Dunder Mifflin Paper Company"
              />
          </li>
          <li>
            <label htmlFor='full-name'>Your Full Name</label>
            <Input
              id='full-name'
              name='fullName'
              required
              type='text'
              placeholder='Michael Scott'
              />
          </li>
          <li>
            <label htmlFor='email'>Your Email</label>
            <Input
              id='email'
              name='email'
              required
              type='email'
              placeholder='m.scott@dunder-mifflin.com'
              />
          </li>
          <li>
            <ShowMe image='copy-consumer-key' />
            <label htmlFor='consumer-key'>Consumer Key</label>
            <textarea
              id='consumer-key'
              name='consumerKey'
              required
              placeholder='3MVG9LBJLAjax_PBcKo3YIBIhvrD3IfwdyUJF5ydxaKRIyj7Gh77ONXhTXgenNFyTqjhnW5cdM5EmlOiYGa3j'
              />
          </li>
          <li>
            <ShowMe image='copy-consumer-secret' />
            <label htmlFor='consumer-secret'>Consumer Secret</label>
            <textarea
              id='consumer-secret'
              name='consumerSecret'
              required
              placeholder='37D94B40829FBB406AE7E164D7541BAF6B6AEF100F3DED49D88FF3E4E75EC495'
              />
          </li>
          <li>
            <ShowMe image='sample-email' />
            <p>
          <strong>Note:</strong> It takes <strong>2-10 minutes</strong> for SalesForce to update.
           If you attempt to get the Callback URL and see an error, just wait a few more minutes and try again.</p>
          <p>
        </p>
            <a href='#' onClick={this.authorize} className='button'>Get CallBack URL From Salesforce</a>
            <label htmlFor='success-url'>Callback URL</label>
            <textarea
            id='successUrl'
            name='successUrl'
            required
            rows={12}
            placeholder='https://login.salesforce.com/services/oauth2/success#access_token=00D6g000000wFz4%21ARwAQB.7GdUxo3M.Bl0mQeGsfjuDA82YZV95ECdLskJB0J0wcBvs983.T3zbGWtlzxqcnGr4ZWcyfx_5ws9_iAsaJT2kRIBF&refresh_token=5Aep8615B7Psrq3qbmKOmIGugaKVxymKOCP6CT4LYhWS0xO67gXADwgQOH43NX31HGz5Avy_IwgmgJGEeM9B3FY&instance_url=https%3A%2F%2Fyour_domain.salesforce.com&id=https%3A%2F%2Flogin.salesforce.com%2Fid%2F00D6g000000wFz4EAE%2F0056g000001VAJUAA4&issued_at=1576615363475&signature=PkZJqNA1mSzko%2FPfKIqJS5VehRUBwfhak5Hb%2FOLQxZI%3D&scope=api+web+refresh_token&token_type=Bearer'
          />
          </li>
        </ol>
      <button className='button' type="submit">Send this information to DataBased Onboarding Team</button>
      </form>
      </div>
    )
  }

  private authorize = (): void => {
    const baseURL = 'https://login.salesforce.com/services/oauth2/authorize?response_type=token'
    const consumerKey = (document.getElementById('consumer-key') as HTMLInputElement).value

    if (consumerKey === '') {
      return
    }
    // eslint-disable-next-line no-restricted-globals
    window.open(`${baseURL}&client_id=${consumerKey}&redirect_uri=https://login.salesforce.com/services/oauth2/success`)
  }
}

export default SFGiveAuthorization
