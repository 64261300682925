import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import FormSection from './FormSection'
import './App.css'

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path='/' component={FormSection} />
      </Switch>
    </Router>
  )
}

export default App
