/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component, ReactElement } from 'react'
import SFAddNewConnectedAppInstructions from '../CRMSection/SFAddNewConnectedAppInstructions'
import './style.css'
import SFConfigureNewConnectedApp from '../CRMSection/SFConfigureNewConnectedApp'
import SFGiveAuthorization from '../CRMSection/SFGiveAuthorization'

const steps = [
  <SFAddNewConnectedAppInstructions />,
  <SFConfigureNewConnectedApp />,
  <SFGiveAuthorization />
]

class FormSection extends Component {
  state = {
    stepIndex: 0,
    component: steps[0]
  }

  previousStep = (): void => {
    const { stepIndex } = this.state
    if (stepIndex > 0) {
      this.setState({
        stepIndex: stepIndex - 1,
        component: steps[stepIndex - 1]
      })
    }
  }

  nextStep = (): void => {
    const { stepIndex } = this.state
    if (stepIndex < steps.length - 1) {
      this.setState({
        stepIndex: stepIndex + 1,
        component: steps[stepIndex + 1]
      })
    }
  }

  finish = async (): Promise<void> => {
    // const {
    //   clientSecret,
    //   consumerKey,
    //   earnings,
    //   roleToRoleData,
    //   uniqueSkills,
    //   userIdToSfUserData
    // } = appState.setup
    // const params = {
    //   clientSecret,
    //   consumerKey,
    //   earnings: {
    //     label: earnings.label,
    //     timePeriod: earnings.timePeriod
    //   },
    //   roleToRoleData: toJS(roleToRoleData),
    //   uniqueSkills: toJS(uniqueSkills),
    //   userIdToSfUserData: strMapToJSON(userIdToSfUserData)
    // }
    console.log('NOW SENDING EMAIL (STUBBED)')
  }

  checkArrowNavigation = (event: KeyboardEvent): void => {
    // @ts-ignore
    if (document.activeElement['value'] === undefined) {
      if (event.key === 'ArrowRight' || event.key === 'Right') {
        this.nextStep()
      } else if (event.key === 'ArrowLeft' || event.key === 'Left') {
        this.previousStep()
      }
    }
  }

  isFinalStep = (): boolean => {
    return false
  }

  componentWillMount (): void {
    document.onkeydown = this.checkArrowNavigation
  }

  componentWillUnmount (): void {
    document.onkeydown = null
  }

  render (): ReactElement {
    const label = 'Grant DataBased Access to Salesforce'
    const { component, stepIndex } = this.state
    const hidePreviousButton = stepIndex === 0
    const numSteps = 3

    return (
      <section className='form-section'>
        <div>
          <h1>{label}</h1>
          <p>Step {stepIndex + 1} of {numSteps}</p>
          <progress value={stepIndex + 1} max={numSteps}></progress>
        </div>
        {component}
        <div className='split'>
          <a
            className={`link-button ${hidePreviousButton ? 'invisible' : ''}`}
            href='#'
            onClick={this.previousStep}
          >&lt; Previous Step</a>
          {
            (stepIndex + 1 === numSteps) ?
              (
                <div></div>
              ) :
              (
                <a
                  className={`button`}
                  onClick={this.nextStep}
                >Next Step &gt;</a>
              )
          }
        </div>
      </section>
    )
  }
}

export default FormSection
